import styled from "styled-components";
import { media } from "../../../../utils/media";

export const Button = styled.button`
    background: transparent;
    border: none;
    outline: none;

    font-size: 2rem;
    position: relative;
    display: inline-block;
    width: 1.8em;
    height: 1.8em;
    cursor: pointer;
    ${media.hamburgerMenu`
        display: none;
    `}
`;

interface IProps {
    active: boolean;
}

export const Span = styled.span<IProps>`
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    margin-top: -0.1em;
    background-color: ${({ theme }) => theme.colors.orange};
    user-select: none;
    transition: background-color 0.3s;
    :before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.orange};
        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transform: ${props =>
            props.active
                ? "translateY(0.35em) translateX(0.65em) rotate(-45deg) scaleX(0.6)"
                : "translateY(-0.5em)"};
    }
    :after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.orange};

        -webkit-transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transform: ${props =>
            props.active
                ? "translateY(-0.35em) translateX(0.65em) rotate(45deg) scaleX(0.6);"
                : "translateY(0.5em)"};
    }
`;
