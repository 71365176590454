import styled from "styled-components";
import { media } from "../../../utils/media";

interface IHeader {
    scrolled: boolean;
}

export const Wrap = styled.header<IHeader>`
    width: 100%;
    height: auto;
    z-index: 5;
    box-sizing: border-box;

    box-shadow: ${props => (props.scrolled ? "0px 4px 24px rgba(0, 0, 0, 0.08)" : "none")};
    transition: box-shadow 100ms linear;

    position: fixed;
    top: 0;
    left: 0;

    background-color: ${({ theme }) => theme.colors.white};
`;

export const Nav = styled.nav`
    height: 100px;
    padding: 0 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${media.tablet`
        padding: 0 50px;
    `}
    ${media.hamburgerMenu`
        padding: 0 120px;
    `}
`;

export const Logo = styled.img`
    cursor: pointer;

    height: 45px;

    ${media.hamburgerMenu`
        width: 198px;
        height: 53px;
    `};
`;
