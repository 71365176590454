export const IS_PRODUCTION = process.env.MODE === "production";

export const PREFIX = IS_PRODUCTION ? "/cilovy-trh" : "";

export function smoothScroll(id, offset) {
    const element = document.getElementById(id);

    if (element) {
        let elemOffset;
        elemOffset = element.offsetTop - offset;

        window.scroll({
            behavior: "smooth",
            left: 0,
            top: elemOffset,
        });
    }
}

export const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length != b.length) return false;

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};
