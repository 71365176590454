import React, { useState, useEffect, useRef } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { navigate } from "gatsby";
import { Wrap, Logo, Nav } from "./styled";

import Hamburger from "./Hamburger";
import LogoBlue from "../../images/logo-blue.svg";
import Menu from "./Menu";
import { smoothScroll } from "../../../utils/util";

interface IHeader {
    activeLink?: number;
    firstClick?: () => void;
    secondClick?: () => void;
    dotaznikClick?: () => void;
    location: any;
}

const Header: React.FC<IHeader> = props => {
    const [scrolled, setScrolled] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [activeItem, setActiveItem] = useState(0);

    const nav = useRef<HTMLDivElement>();

    const toggleMenu = () => {
        if (isMenuOpen) {
            enableBodyScroll(nav.current);
        } else {
            disableBodyScroll(nav.current);
        }

        setMenuOpen(!isMenuOpen);
    };

    const turnScrollOn = () => {
        enableBodyScroll(nav.current);
        setMenuOpen(false);
    };

    const handleScroll = () => {
        const scrollHeight = 10;

        if (window.scrollY < scrollHeight) {
            setScrolled(false);
        } else if (!scrolled && window.scrollY > scrollHeight) {
            setScrolled(true);
        }
    };

    const scrollListener = () => {
        window.requestAnimationFrame(() => {
            handleScroll();
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollListener);

        return () => {
            window.removeEventListener("scroll", scrollListener);
        };
    }, []);

    const logoClick = () => {
        setMenuOpen(false);
        enableBodyScroll(nav.current);
        setActiveItem(0);
        navigate("/");
        setTimeout(() => {
            smoothScroll("about", 140);
        }, 100);
    };

    const firstItemClick = () => {
        turnScrollOn();
        props.firstClick && props.firstClick();
        setTimeout(() => {
            setActiveItem(1);
        }, 50);
    };

    const secondItemClick = () => {
        turnScrollOn();
        props.secondClick && props.secondClick();
        setTimeout(() => {
            setActiveItem(2);
        }, 50);
    };

    const dotaznikItemClick = () => {
        turnScrollOn();
        props.dotaznikClick && props.dotaznikClick();
        setTimeout(() => {
            setActiveItem(0);
        }, 50);
    };

    return (
        <Wrap scrolled={scrolled} ref={nav}>
            <Nav>
                <Logo
                    src={LogoBlue}
                    alt="patria finance logo"
                    onClick={() => logoClick()}
                />
                <Menu
                    activeLink={activeItem}
                    isOpen={isMenuOpen}
                    location={props.location}
                    firstClick={() => firstItemClick()}
                    secondClick={() => secondItemClick()}
                    dotaznikClick={dotaznikItemClick}
                />
                <Hamburger
                    active={isMenuOpen}
                    toggleMenu={() => toggleMenu()}
                />
            </Nav>
        </Wrap>
    );
};

export default Header;
