// After editing theme,
// do not forget to change types in types/styled.d.ts
export default {
    borderRadius: "5px",
    colors: {
        white: "#fff",
        blue: "#000046",
        orange: "#F59100",
        lightOrange: "#FFF4E4",
        gray: "#555569",
    },
    fonts: {
        verdana: "Verdana, Geneva, Tahoma, sans-serif",
        openSans: "'Open Sans', sans-serif",
    },
};
