import React from "react";
import { Content, DoubleBorder, Wrap, Links, Legal } from "./styled";

interface IProps {
    text: string;
}

const Footer: React.FC<IProps> = ({ text }) => {
    return (
        <Wrap>
            <DoubleBorder />

            <Content>
                <div>
                    <Links>INVESTIČNÍ PRODUKTY</Links>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/produkty-a-trhy/produkty/akcie"
                                rel="noopener noreferrer"
                            >
                                <Links>Akcie</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/produkty-a-trhy/produkty/derivaty"
                                rel="noopener noreferrer"
                            >
                                <Links>Deriváty</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/produkty-a-trhy/produkty/etf"
                                rel="noopener noreferrer"
                            >
                                <Links>Fondy a ETF</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/produkty-a-trhy/produkty/dluhopisy"
                                rel="noopener noreferrer"
                            >
                                <Links>Dluhopisy</Links>
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <Links>PLATFORMY A NÁSTROJE </Links>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/sluzby/web-trader"
                                rel="noopener noreferrer"
                            >
                                <Links>WebTrader</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/sluzby/mobiletrader"
                                rel="noopener noreferrer"
                            >
                                <Links>MobileTrader</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/sluzby/inteligentni-pokyny"
                                rel="noopener noreferrer"
                            >
                                <Links>Inteligentní pokyny</Links>
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <Links>VZDĚLÁVÁNÍ</Links>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/vzdelavani/zaciname-s-investovanim/jak-uspet"
                                rel="noopener noreferrer"
                            >
                                <Links>Jak uspět</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/vzdelavani/zaciname-s-investovanim/skola-investora"
                                rel="noopener noreferrer"
                            >
                                <Links>Škola investora</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/vzdelavani/zaciname-s-investovanim/akce"
                                rel="noopener noreferrer"
                            >
                                <Links>Semináře/Webináře</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/vzdelavani/pro-snazsi-obchodovani/instruktazni-videa"
                                rel="noopener noreferrer"
                            >
                                <Links>Instruktážní videa</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/vzdelavani/pro-snazsi-obchodovani/manualy"
                                rel="noopener noreferrer"
                            >
                                <Links>Manuály</Links>
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <Links>DOKUMENTY</Links>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href="https://cdn.patria-finance.cz/Informace-o-telefonnim-a-bankovnim-spojeni.pdf"
                                rel="noopener noreferrer"
                            >
                                <Links>Bankovní spojení</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://cdn.patria-finance.cz/Sazebnik-PD.pdf"
                                rel="noopener noreferrer"
                            >
                                <Links>Sazebník poplatků</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://cdn.patria-finance.cz/Ramcova-smlouva.pdf"
                                rel="noopener noreferrer"
                            >
                                <Links>Rámcová smlouva</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/otevrit-ucet/dokumenty/vzory-smluv"
                                rel="noopener noreferrer"
                            >
                                <Links>Ostatní dokumenty</Links>
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <Links>PATRIA FINANCE</Links>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/otevrit-ucet/patria-finance/o-patria-finance"
                                rel="noopener noreferrer"
                            >
                                <Links>O Patria Finance</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/otevrit-ucet/patria-finance/kontakt"
                                rel="noopener noreferrer"
                            >
                                <Links>Kontakt</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/otevrit-ucet/patria-finance/skupina-patria"
                                rel="noopener noreferrer"
                            >
                                <Links>Skupina Patria</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria-finance.cz/otevrit-ucet/patria-finance/podminky-pouzivani"
                                rel="noopener noreferrer"
                            >
                                <Links>Podmínky používání stránek</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://www.patria.cz/cookies.html"
                                rel="noopener noreferrer"
                            >
                                <Links>Informace o cookies</Links>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                href="https://cdn.patria-finance.cz/Informace-o-zpracovani-osobnich-udaju.pdf"
                                rel="noopener noreferrer"
                            >
                                <Links>Ochrana osobních údajů</Links>
                            </a>
                        </li>
                    </ul>
                </div>
            </Content>
            <Legal>{text}</Legal>
        </Wrap>
    );
};

export default Footer;
