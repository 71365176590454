import React from "react";

import { ItemsWrap, MenuItem, Button, Wrap } from "./styled";

interface IMenu {
    isOpen: boolean;
    activeLink?: number;
    firstClick?: () => void;
    secondClick?: () => void;
    dotaznikClick?: () => void;
}

const Menu: React.FC<IMenu> = ({
    activeLink,
    isOpen,
    firstClick,
    secondClick,
    dotaznikClick,
    location,
}) => {
    const pathname: string = location && location.pathname;
    return (
        <Wrap isOpen={isOpen}>
            <ItemsWrap>
                <MenuItem
                    active={
                        activeLink === 0 &&
                        (pathname === "/" || pathname === "/cilovy-trh/")
                    }
                    onClick={() => dotaznikClick && dotaznikClick()}
                >
                    Dotazník
                </MenuItem>
                <MenuItem
                    active={
                        activeLink === 1 || pathname.includes("o-instrumentech")
                    }
                    onClick={() => firstClick && firstClick()}
                >
                    O instrumentech
                </MenuItem>
                <MenuItem
                    active={activeLink === 2 || pathname.includes("slovnik")}
                    onClick={() => secondClick && secondClick()}
                >
                    Slovník pojmů
                </MenuItem>
            </ItemsWrap>
            <Button
                href="https://patria-finance.cz"
                target="_blank"
                rel="noopener noreferrer"
            >
                Patria Finance
            </Button>
        </Wrap>
    );
};

export default Menu;
