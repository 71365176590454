import styled from "styled-components";
import { media } from "../../../../utils/media";

interface IWrap {
    isOpen: boolean;
}

export const Wrap = styled.div<IWrap>`
    display: ${props => (props.isOpen ? "flex" : "none")};
    visibility: ${props => (props.isOpen ? "visible" : "hidden")};

    position: fixed;
    top: 50px;
    left: 0;
    z-index: -1;

    width: 100vw;
    height: 100vh;
    background-color: ${({ theme }) => theme.colors.white};

    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    ${media.hamburgerMenu`
        position: static;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 100px;
        z-index: 6;
        visibility: visible;
    `}
`;

export const ItemsWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 5em;

    ${media.hamburgerMenu`
        flex-direction: row;
        align-items: center;
        margin: 0 2em 0 auto;
    `}
`;

interface IMenuItem {
    active?: boolean;
}

export const MenuItem = styled.a<IMenuItem>`
    color: ${props => (props.active ? props.theme.colors.orange : props.theme.colors.blue)};
    transition: color 100ms linear;
    cursor: pointer;

    margin: 1rem 0;
    padding: 0.4em;

    font-size: 1.5rem;
    line-height: 1.7rem;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: ${({ theme }) => theme.fonts.openSans};
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.colors.orange};
    }

    ${media.hamburgerMenu`
    margin: 0 1em;

    `}
`;

export const Button = styled.a`
    visibility: visible;
    display: block;

    justify-self: flex-end;

    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: ${({ theme }) => theme.fonts.openSans};
    text-decoration: none;
    cursor: pointer;

    margin: 2em 0 0 5em;
    padding: 1.2em 3.8em;

    transition: border 100ms linear;

    border: 1px solid ${({ theme }) => theme.colors.lightOrange};
    color: ${({ theme }) => theme.colors.orange};
    background-color: ${({ theme }) => theme.colors.lightOrange};

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.orange};
    }

    ${media.hamburgerMenu`
        margin: 0;
        padding: 1.2em 2.9em;
    `}
`;
