// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/Users/martinrosenberg/Desktop/patria-finance-faq/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/martinrosenberg/Desktop/patria-finance-faq/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-o-instrumentech-tsx": () => import("/Users/martinrosenberg/Desktop/patria-finance-faq/src/pages/o-instrumentech.tsx" /* webpackChunkName: "component---src-pages-o-instrumentech-tsx" */),
  "component---src-pages-slovnik-tsx": () => import("/Users/martinrosenberg/Desktop/patria-finance-faq/src/pages/slovnik.tsx" /* webpackChunkName: "component---src-pages-slovnik-tsx" */)
}

