import React, { useEffect } from "react";
import Helmet from "react-helmet";
import AOS from "aos";
import smoothscroll from "smoothscroll-polyfill";
import { StaticQuery, graphql, navigate } from "gatsby";
import { ThemeProvider } from "styled-components";
import theme from "../theme";
import og from "../images/og.png";

import Header from "./Header";
import Footer from "./Footer";
import { GlobalStyle } from "../globalStyle";

import "sanitize.css";
import { smoothScroll } from "../../utils/util";

import "aos/dist/aos.css";

const Layout: React.FC = ({
    children,
    location,
}: {
    children: React.ReactNode;
    location: any;
}) => {
    useEffect(() => {
        smoothscroll.polyfill();
        AOS.init({
            duration: 2000,
            once: true,
        });
    }, []);

    const firstItemClicked = () => {
        navigate("/o-instrumentech");
    };

    const secondItemClicked = () => {
        navigate("/slovnik");
    };

    const dotaznikItemClicked = () => {
        navigate("/");
        setTimeout(() => {
            smoothScroll("faq", 140);
        }, 100);
    };

    return (
        <ThemeProvider theme={theme}>
            <>
                <Helmet
                    title="Jak si stojí Vaše znalosti v oblasti investic?"
                    meta={[
                        {
                            name: "description",
                            content:
                                "Jak si stojí Vaše znalosti v oblasti investic? Víte, jakou kombinaci výnosu a rizika mají jednotlivé instrumenty?",
                        },
                        {
                            name: "og:description",
                            content:
                                "Víte, jakou kombinaci výnosu a rizika mají jednotlivé instrumenty?",
                        },
                        {
                            name: "og:title",
                            content:
                                "Jak si stojí Vaše znalosti v oblasti investic?",
                        },
                        { name: "og:image", content: og },
                        {
                            name: "og:url",
                            content: "https://www.patria-finance.cz",
                        },
                    ]}
                >
                    <html lang="en" />
                </Helmet>
                <GlobalStyle />
                <Header
                    firstClick={() => firstItemClicked()}
                    secondClick={() => secondItemClicked()}
                    dotaznikClick={dotaznikItemClicked}
                    location={location}
                />
                <div style={{ overflowX: "hidden" }}>{children}</div>
                <Footer text="Informace uveřejněné na stránkách Patria Finance, a.s. nejsou (není-li uvedeno jinak) analýzou investičních příležitostí ani investičním doporučením. Názory a doporučení zde uvedené neberou v úvahu situaci a osobní poměry jednotlivých investorů, jejich finanční situaci, znalosti, zkušenosti, cíle nebo potřeby. Investiční nástroje, které mohou zákazníci prostřednictvím Patria Finance a.s. nakoupit/prodat nemusejí být pro konkrétní zákazníky vhodné. S obchodováním na kapitálovém trhu je spojeno riziko ztráty investice, rizika spojená s jednotlivými investičními nástroji a další informace týkající se obchodování na kapitálovém trhu a společnosti Patria Finance, a.s. jsou uveřejněny na www.patria-finance.cz." />
            </>
        </ThemeProvider>
    );
};

export default Layout;
