import styled from "styled-components";
import { media } from "../../../utils/media";

export const Wrap = styled.footer`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.white};
    padding: 30px 20px;
    ${media.desktop`
        padding: 80px 120px;
    `}
`;

export const Links = styled.p`
    color: inherit;
    margin: 12px 0;
    font-size: 12px;
    line-height: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
`;

export const DoubleBorder = styled.div`
    border-top: 2px solid ${({ theme }) => theme.colors.orange};
    position: relative;
    :before {
        content: " ";
        position: absolute;
        width: 100%;
        top: 5px;
        left: 0px;
        border-top: 1px solid ${({ theme }) => theme.colors.orange};
    }
`;

export const Content = styled.div`
    grid-area: links;
    display: flex;
    padding-top: 48px;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: content-box;

    div > p {
        color: inherit;

        font-weight: bold;
        text-align: left;
    }

    p {
        text-align: left;
    }

    ul {
        margin: 16px 0 48px;
        padding: 0;
    }

    ul li {
        list-style-type: none;
    }

    ul li a {
        color: inherit;
        text-decoration: none;

        p {
            margin: 2px 0;
        }

        p:hover {
            color: ${({ theme }) => theme.colors.orange};
        }
    }

    ul li:first-child a p {
        margin-top: 10px;
    }

    ${media.desktop`
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 50px;
    `}
`;

export const Legal = styled.p`
    text-align: center;

    margin: 50px auto 0;
    padding-bottom: 25px;
    color: rgba(196, 196, 196, 0.81);
    font-size: 9px;
    line-height: 23px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    ${media.tablet`
        max-width: 90%;
    `}
`;
