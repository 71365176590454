import React from "react";
import { Button, Span } from "./styled";

interface IProps {
    toggleMenu: () => void;
    active: boolean;
}

const Hamburger: React.FC<IProps> = ({ toggleMenu, active }) => {
    return (
        <Button onClick={toggleMenu}>
            <Span active={active} />
        </Button>
    );
};

export default Hamburger;
